<template>
  <div class="ranking">
    <div>
      <div class="history-title">
        <h2>Best scores</h2>
      </div>
      <div class="history-list">
        <div :class="{'history-item': true, 'current': i + 1 === rank}" v-for="(item, i) in ladder" :key="item.id">
          <div class="history-item-index">
            {{ i + 1 }}
          </div>
          <div class="history-item-photo">
            <img :src="item.photo" alt="">
          </div>
          <div class="history-item-name">
            {{ item.username }}
          </div>
          <div class="history-item-score">
            <b>{{ item.score }}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'RankingView',
  data () {
    return {
      ladder: [],
      rank: '--'
    }
  },
  computed: {
    ...mapGetters({
      url: 'api/url',
      loggedIn: 'auth/loggedIn',
      uid: 'auth/uid',
    }),
  },
  mounted() {
    const data = {
      "action": "getRanking"
    }
    fetch(this.url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(res => {
      return res.json()
    }).then(data => {
      if (data.ladder && data.error === 0) {
        this.ladder = data.ladder
      }
    }).catch(err => {
      console.log(err)
    })
  }
}
</script>

<style lang="scss" scoped>
  .history-title {
    color: var(--green-color);
  }
  .history-list {
    max-height: 60vh;
    overflow-y: scroll;
    .history-item {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
      font-size: 20px;
      font-weight: normal;
      color: #5a765c;
      text-shadow: 0 0 1px #00000021;
      margin: 10px;
      border-bottom: 1px solid #a1a1a1;
      padding-bottom: 10px;
      gap: 10px;
      &.current {
        font-weight: bold;
      }
      &-score {
        margin-left: auto;
      }
      &:last-child {
        border-bottom: none;
      }
      .history-item-photo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        img {
          width: 35px;
          height: 35px;
          border-radius: 50%;
        }
      }
    }
  }
</style>
