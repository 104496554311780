<template>
  <div class="instructions">
    <p><b>Lexidus</b> (Lexi Ludus - Word Game) is a mixture of <b>Wordle</b> and <b>Scrabble</b> where you have to find a five letters word from the given random letters.
      The goal is to use letters with the most points. The more points you get, the higher you will climb in the ranking ladder.</p>
    <instructions-info></instructions-info>
  </div>
</template>

<script>
import InstructionsInfo from "@/components/instructionsInfo.vue";
export default {
  name: 'InstructionsView',
  components: {InstructionsInfo}
}
</script>

<style lang="scss" scoped>
  .instructions {
    padding-bottom: 100px;
  }
</style>
