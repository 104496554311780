import { createStore } from 'vuex'
import { signOut, getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

const auth = {
    namespaced: true,
    state:  () => ({
        user: {
            loggedIn: false,
            data: null
        }
    }),
    getters: {
        user(state){
            return state.user
        },
        loggedIn(state){
            return state.user.loggedIn
        },
        uid(state){
            return state.user.data?.uid ? state.user.data.uid : ''
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user
        }
    },
    actions: {
        signInWithGoogle() {
            const provider = new GoogleAuthProvider();
            return signInWithPopup(getAuth(), provider)
                .then(res => {
                    const { uid, email, emailVerified, photoURL, displayName } = res.user
                    return { uid, email, emailVerified, photoURL, displayName }
                })
                .catch(error => {
                    console.log(error.message)
                })
        },
        signOut() {
            signOut(getAuth())
                .then()
                .catch((error) => {
                    console.log(error.message)
                })
        }
    }
}
const api = {
    namespaced: true,
    state:  () => ({
        url: 'https://lexidus.me/api/'
    }),
    getters: {
        url(state){
            return state.url
        }
    },
    mutations: {},
    actions: {}
}
const game = {
    namespaced: true,
    state:  () => ({
        scores: {},
        todayWord: {
            date: '',
            score: 0,
            word: ''
        }
    }),
    getters: {
        getScores(state){
            return state.scores
        },
        getScoreByDate(state){
            return date => {
                return state.scores.find(score => score.date === date)
            }
        },
        getTodayWord(state){
            return state.todayWord
        },
        savedWord(state){
            return state.todayWord.word !== ''
        },
        getTodayScore(state){
            return state.todayWord.score
        }
    },
    mutations: {
        setScores(state, scores) {
            state.scores = scores
        },
        addScore(state, score) {
            state.scores.push(score)
        },
        setTodayWord(state, todayWord) {
            state.todayWord = todayWord
        }
    },
    actions: {}
}

const store = createStore({
    modules: {
        auth,
        api,
        game
    }
})

export default store
