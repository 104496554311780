<template>
  <div class="letters-container">
    <div v-if="!showModal">
      <div class="letters" v-if="randomLetters.length">
        <div
            :class="['letter', getLetterColor(letter.score)]"
            v-for="(letter, i) in randomLetters"
            :key="i"
            :data-score="letter.score"
            @click="selectLetter(i)"
        >
          {{ letter.letter }}
          <span>{{ letter.score }}</span>
        </div>
      </div>
      <div class="words">
        <div :class="['word-letter', getLetterScore(i)]" v-for="i in lettersCount" :key="i">
          <div
              v-if="selectedLetters[i - 1]"
              :class="['letter', getLetterColor(selectedLetters[i - 1]?.score)]"
              @click="removeLetter(selectedLetters[i - 1])"
          >
            {{ selectedLetters[i - 1]?.letter }}
            <span>{{ selectedLetters[i - 1]?.score }}</span>
          </div>
        </div>
      </div>
      <div class="actions" v-if="randomLetters.length">
      <button
          v-if="!showModal"
          class="btn effect6"
          :disabled="disableButton"
          @click="submitWord()"
      >
        Ready
      </button>
      <div v-if="message" class="alert-container">
        <div class="alert error">
          <span class="alertClose" @click="message = ''">X</span>
          <span class="alertText">
            {{ message }} <span class="reset" @click="resetLetters()">Reset</span>
            <br class="clear"/>
          </span>
        </div>
      </div>
    </div>
    </div>
    <div  class="modal effect1" v-else>
      <div class="modal-title">
        <h2>Good job!</h2>
      </div>
      <div class="score">
        <span class="title">Score</span>
        <span class="points">{{ totalScore }}</span>
        <div class="btn-container" v-if="!loggedIn">
          <p class="text-desc">Want to save your score?</p>
          <sign-in-button/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SignInButton from "@/components/SignInButton.vue";

export default {
  name: "lettersBar",
  components: {SignInButton},
  data() {
    return {
      randomLetters: [],
      lettersCount: 5,
      selectedLetters: [],
      disableButton: true,
      message: '',
      showModal: false,
      userMessage: '',
      letterScore: {},
    }
  },
  computed: {
    ...mapGetters({
      url: 'api/url',
      loggedIn: 'auth/loggedIn',
      uid: 'auth/uid',
      totalScore: 'game/getTodayScore',
    }),
    getLetterColor () {
      return score => score <= 3 ? "green" : score <= 6 ? "yellow" : "blue";
    },
    getLetterScore () {
      return (i) => {
        if (this.letterScore.letter) {
          return this.letterScore.letter === i ? 'letter-score score' + this.letterScore.score : ''
        }
        return ''
      }
    }
  },
  methods: {
    getDate () {
      const currentDate = this.$route.params.date ? new Date(this.$route.params.date) : new Date();
      const ukLocale = 'en-GB';
      const ukTimeZone = 'Europe/London';
      const formattingOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: ukTimeZone,
      };
      const ukDateParts = currentDate.toLocaleDateString(ukLocale, formattingOptions);
      return ukDateParts.split('/').reverse().join('');
    },
    selectLetter (index) {
      if(this.selectedLetters.length >= this.lettersCount) return;
      this.selectedLetters.push(this.randomLetters[index]);
      this.randomLetters = this.randomLetters.filter((l, i) => i !== index)
      this.message = ''
    },
    removeLetter (letter) {
      this.randomLetters.push(letter);
      this.selectedLetters = this.selectedLetters.filter(l => l !== letter)
    },
    submitWord () {
      const word = this.selectedLetters.map(l => l.letter).join('')
      const date = this.getDate()
      const url = this.url
      const data = {
        "action": "checkWord",
        "word": word,
        "date": date,
        "uid": this.uid
      }
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }).then(res => {
        return res.json()
      }).then(data => {
        console.log(data)
        if(data.error === 0) {
          const todayWord = {
            date: date,
            score: data.data.totalScore,
            word: word
          }
          this.$store.commit('game/setTodayWord',  todayWord)
          localStorage.setItem('word' + date, JSON.stringify(todayWord))
          this.showModal = true
        }
        else {
          this.message = data.message ? data.message : 'Something went wrong!'
        }
      }).catch(err => {
        console.log(err)
      })
    },
    resetLetters () {
      this.randomLetters = [...this.randomLetters, ...this.selectedLetters]
      this.selectedLetters = []
      this.message = ''
    }
  },
  watch:  {
    randomLetters() {
      this.disableButton = this.selectedLetters.length < this.lettersCount
    },
    selectedLetters() {
      this.disableButton = this.selectedLetters.length < this.lettersCount
    }
  },
  mounted() {
    const date = this.getDate()
    let word = localStorage.getItem('word' + date)
    if (word) {
      word = JSON.parse(word)
      this.$store.commit('game/setTodayWord',  word)
      this.showModal = true
    }
    fetch(this.url + '?date=' + date).then(res => {
      return res.json()
    }).then(data => {
      if (data.error === 0) {
        this.randomLetters = data.data.letters
        if (data.data.letterScore?.letter >= 1 && data.data.letterScore?.letter <= 5) {
          this.letterScore = data.data.letterScore
        }
      }
    }).catch(err => {
      console.log(err)
    })
  }
}
</script>

<style lang="scss" scoped>
  .actions {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: space-around;
    align-items: center;
  }
  .actions .btn {
    width: 100%;
    max-width: 150px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background: var(--green-color100);
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    &:disabled {
      background: var(--disabled-color);
      cursor: not-allowed;
    }
  }
  .modal {
    position: fixed;
    background: #f0f0f087;
    width: 80vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    backdrop-filter: blur(15px);
    padding: 40px 10px;
    z-index: 2;
  }
  .modal .score {
    width: 200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 20px;
      text-transform: uppercase;
      color: #2d5016;
    }
    .points {
      font-size: 40px;
      color: #2d5016;
    }
  }
  input.input {
    border: 1px solid #aeaeae;
    background: #e5e5e5;
    padding: 5px 15px;
    color: #4b4b4b;
    border-radius: 3px;
    margin: 15px 0;
    font-size: 18px;
    text-align: center;
  }
  .btn-container {
    .text-desc {
      font-size: 20px;
    }
  }
</style>
