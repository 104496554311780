<template>
  <div class="header-nav">
    <div class="logo">lexidus</div>
  </div>
</template>

<script>
export default {
  name: 'headerNav'
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Lobster+Two:wght@400;700&display=swap');
.header-nav {
  .logo {
    font-family: 'Lobster Two', cursive;
    font-size: 62px;
    margin: 0;
    padding: 0;
    font-weight: 700;
    background-image: linear-gradient(to right, var(--logo-color100), var(--logo-color200));
    -webkit-background-clip: text;
    color: transparent;
  }
}
</style>
