<template>
  <div>
    <div v-if="!loggedIn" class="google-btn" @click="signIn()">
      <div class="google-icon-wrapper">
        <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
      </div>
      <p class="btn-text"><b>Sign in with google</b></p>
    </div>
    <div v-else class="google-btn" @click="signOut()">
      <p class="btn-logout-text"><b>Logout</b></p>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SignInButton",
  methods: {
    ...mapActions('auth', ['signInWithGoogle', 'signOut']),
    signIn() {
      this.signInWithGoogle().then((res) => {
        console.log(res)
        const url = this.url
        const data = {
          "action": "saveUser",
          "photo": res.photoURL,
          "email": res.email,
          "id": res.uid,
          "username": res.displayName,
          "status": res.emailVerified
        }
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }).then(res => {
          return res.json()
        }).then(data => {
          if (data.error === 0 && this.savedWord) {
              const dataToSave = {
                "action": "saveData",
                "word": this.todayWord.word,
                "date": this.todayWord.date,
                "uid": res.uid
              }
              fetch(url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSave),
              }).catch(err => {
                console.log(err)
              })
          }
        }).catch(err => {
          console.log(err)
        })
      })
    }
  },
  computed: {
    ...mapGetters({
      url: 'api/url',
      loggedIn: 'auth/loggedIn',
      savedWord: 'game/savedWord',
      todayWord: 'game/getTodayWord'
    }),
  }
}
</script>

<style lang="scss" scoped>
$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669F2;

.google-btn {
  width: 194px;
  height: 42px;
  background-color: $google-blue;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
.google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: $white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.google-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
}
.btn-text {
  float: right;
  margin: 11px 11px 0 0;
  color: $white;
  font-size: 14px;
  letter-spacing: 0.2px;
}
  .btn-logout-text {
    margin: 0;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.2px;
    position: relative;
    display: block;
    top: 13px;
  }
&:hover {
   box-shadow: 0 0 6px $google-blue;
 }
&:active {
   background: $button-active-blue;
 }
}
</style>
