<template>
  <div class="home">
    <letters-bar></letters-bar>
  </div>
</template>

<script>
import LettersBar from "@/components/lettersBar.vue";
import {mapGetters} from "vuex";
export default {
  name: 'HomeView',
  components: {LettersBar},
  computed: {
    ...mapGetters('auth', ['user'])
  }
}
</script>

<style lang="scss" scoped>
  .home {
    margin-top: 90px;
  }
</style>
