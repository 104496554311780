<template>
  <div class="footer-nav">
    <router-link to="/" class="footer-nav-item">
      <img src="../assets/icons/home.svg" alt="">
    </router-link>
    <router-link to="/history" class="footer-nav-item">
      <img src="../assets/icons/history.svg" alt="">
    </router-link>
    <router-link to="/instructions" class="footer-nav-item">
      <span class="tooltip" v-if="showTooltip">How to play</span>
      <img src="../assets/icons/teacher.svg" alt="">
    </router-link>
    <router-link to="/ranking" class="footer-nav-item">
      <img src="../assets/icons/base.svg" alt="">
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'footerNav',
  data() {
    return {
      showTooltip: false
    }
  },
  mounted() {
    if (!localStorage.getItem('firstTime')) {
      setTimeout(() => {
        this.showTooltip = true
        localStorage.setItem('firstTime', 1)
        // click outside tooltip to close it and remove event listener
        document.addEventListener('click', () => {
          this.showTooltip = false
        })
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
  .footer-nav {
    position: fixed;
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    background: #e5e5e5e6;
    z-index: 5;
    border-radius: 6px;
    box-shadow: 0 1px 3px #848484;
    width: 95%;
    left: 0;
    right: 0;
    max-width: 500px;
    padding: 8px 0;
    margin: 10px auto;
    &-item {
      width: 35px;
      height: 35px;
      z-index: 1;
      position: relative;
      img {
        filter: invert(0.30);
      }
      &.router-link-active img{
        filter: invert(0);
      }
      img {
        width: 100%;
      }
      .tooltip {
        position: absolute;
        margin: 0 auto;
        width: 120px;
        height: 32px;
        background: #e5e5e5e6;
        border-radius: 6px;
        box-shadow: 0 1px 3px #848484;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: black;
        z-index: 2;
        transform: translate(-40px, -58px);
        &:after {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -15px;
          border-width: 15px;
          border-style: solid;
          border-color: #e5e5e5e6 transparent transparent transparent;
        }
      }
    }
  }
</style>
