<template>
  <div>
    <h2>How to play:</h2>
    <p>
      Click letters from the bar with the random letters to form a word.
    </p>
    <h2>How to get points:</h2>
    <p>
      Each letter has a different value.
      The more common the letter, the less points it has. The less common the letter, the more points it has.
      The following table shows the value of each letter:
    </p>
    <div class="letters">
      <div class="letter">a<span>1</span></div>
      <div class="letter yellow">h<span>4</span></div>
      <div class="letter blue">q<span>10</span></div>
    </div>
    <div class="example">
      There are also points in the letter placeholders. These are random and can be x2 or x3 to the letter that is above them.
    </div>
    <div class="words">
      <div class="word-letter letter-score score2"></div>
      <div class="word-letter letter-score score3"></div>
    </div>
    <div class="example">
      for <b>example</b>, the word "hello" has <b>10 points</b>: 4 points for the letter "H" and 1 point for "E", "L", "L". Also letter "O" is x3, so it has 3 points.
    </div>
    <div class="letters">
      <div class="words">
        <div class="word-letter">
          <div class="letter yellow">h<span>e</span></div>
        </div>
        <div class="word-letter">
          <div class="letter">e<span>1</span></div>
        </div>
        <div class="word-letter">
          <div class="letter">l<span>1</span></div>
        </div>
        <div class="word-letter">
          <div class="letter">l<span>1</span></div>
        </div>
        <div class="word-letter letter-score score3">
          <div class="letter">o<span>1</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "instructionsInfo"
}
</script>

<style lang="scss" scoped>
.words {
  margin-top: 0;
}
.letters {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 15px 25px;
}
.letter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 41px;
  aspect-ratio: 1;
  border-radius: 5px;
  background-size: 215px;
  background: var(--green-color);
  border: 1px solid var(--color-border);
  color: var(--text-color-light);
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  text-transform: uppercase;
  z-index: 1;
}
.letter.blue {
  background: var(--blue-color);
}
.letter.yellow {
  background: var(--yellow-color);
}
.letter span {
  position: absolute;
  bottom: 0;
  right: 4px;
  font-size: 10px;
  font-weight: bold;
  color: var(--text-color-light);
}
</style>
