<template>
  <div class="history">
    <div class="btn-container" v-if="!loggedIn">
      <h4 class="text-desc">Want to save your score?</h4>
      <sign-in-button class="login-btn"/>
    </div>
    <div v-else class="container">
      <div class="history-title">
        <h2>Total Points: <span>{{ totalPoints }}</span></h2>
      </div>
      <div class="history-list">
        <div class="history-item" v-for="item in history" :key="item.id">
          <div class="history-item-date">
            {{ item.date }}
          </div>
          <div class="history-item-title" v-if="item.word">
            {{ item.word }}
          </div>
          <div class="history-item-title" v-else>
            <router-link :to="`/date/${item.date}`" class="empty">Find it!</router-link>
          </div>
          <div class="history-item-score">
            <b>{{ item.score }}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SignInButton from "@/components/SignInButton.vue";
export default {
  name: 'HistoryView',
  components: {SignInButton},
  data () {
    return {
      history: [],
      startDate: '2022-12-31',
      totalPoints: 0,
    }
  },
  computed: {
    ...mapGetters({
      url: 'api/url',
      loggedIn: 'auth/loggedIn',
      uid: 'auth/uid'
    })
  },
  methods: {
    formatDate(input) {
      const year = input.slice(0, 4);
      const month = input.slice(4, 6);
      const day = input.slice(6, 8);

      return `${year}-${month}-${day}`;
    },
    getDates (startDate) {
      let dates = []
      let currentDate = new Date(startDate)
      let now = new Date()
      while (currentDate < now) {
        currentDate = new Date(currentDate)
        currentDate.setDate(currentDate.getDate() + 1)
        dates.push(currentDate.toISOString().slice(0, 10))
      }
      return dates
    },
    getHistory () {
      if (this.loggedIn) {
        const dates = this.getDates(this.startDate).reverse()
        const data = {
          "action": "getHistory",
          "uid": this.uid
        }
        fetch(this.url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        }).then(res => {
          return res.json()
        }).then(data => {
          if (data.error === 0 && data.data.length) {
            this.history = dates.map(day => {
              let item = data.data.find(item => this.formatDate(item.date) === day)
              if (item) {
                this.totalPoints += +item.score
                item['date'] = day
                return item
              }
              return { date: day, word: '', score: 0 }
            })
          } else {
            this.history = dates.map(day => {
              return { date: day, word: '', score: 0 }
            })
          }
        }).catch(err => {
          console.log(err)
        })
      }
    }
  },
  watch: {
    loggedIn () {
      this.getHistory()
    }
  },
  mounted () {
    this.getHistory()
  }
}
</script>

<style lang="scss" scoped>
  .btn-container {
    margin-top: 80px;
    .login-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
  .history-title {
    color: var(--green-color);
  }
  .history-list {
    max-height: 60vh;
    overflow-y: scroll;
    .history-item {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
      font-weight: normal;
      color: #5a765c;
      text-shadow: 0 0 1px #00000021;
      margin: 10px 40px;
      border-bottom: 1px solid #a1a1a1;
      padding-bottom: 10px;
      &:last-child {
        border-bottom: none;
      }
      &-title {
        font-weight: bold;
        a.empty {
          display: block;
          font-size: 14px;
          text-decoration: none;
          color: #eaeaea;
          background: #99b59d;
          padding: 5px 15px;
          border-radius: 3px;
          box-shadow: 0px 1px 2px #00000066;
          font-weight: normal;
        }
      }
    }
  }
</style>
