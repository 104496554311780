<template>
  <div class="login">
    <h2>Login to save score</h2>
    <div class="button-container">
      <sign-in-button/>
    </div>

  </div>
</template>

<script>
import SignInButton from "@/components/SignInButton.vue";
export default {
  name: "LoginView",
  components: {SignInButton}
}
</script>

<style scoped>
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>