import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { initializeApp } from 'firebase/app'
import {
    browserLocalPersistence,
    getAuth,
    setPersistence
} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyA8pstkKhYVG_3NlN_ywvPOQDDDDWhK14w",
    authDomain: "worbble-d034b.firebaseapp.com",
    projectId: "worbble-d034b",
    storageBucket: "worbble-d034b.appspot.com",
    messagingSenderId: "521607453875",
    appId: "1:521607453875:web:39b47e72040678ddd124c6",
    measurementId: "G-GPS4E334J7"
};

initializeApp(firebaseConfig);
export const auth = getAuth();
setPersistence(auth, browserLocalPersistence).then()
auth.onAuthStateChanged(user => {
    if (user) {
        store.commit('auth/setUser', {
            loggedIn: true,
            data: user
        })
    } else {
        store.commit('auth/setUser', {
            loggedIn: false,
            data: null
        })
    }
})

createApp(App).use(store).use(router).mount('#app')
